body.about {
	.photo {
		width: 100%;
		text-align: center;
		img {
			border-radius: 50%;
			border: 3px solid white;
			width: 200px;
			height: 200px;
			object-fit: cover;
			@include media-breakpoint-down(md) {
				width: 150px;
				height: 150px;
			}
		}
	}

	.side-bar {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
		.card-block {
			padding-bottom: 0;
		}
	}
}

h3 small {
	color: $gray-light;
	font-size: $font-size-xs;
}


ul.sns-list {
	li {
		list-style-type: none;
		display: inline-block;
		text-decoration: underline;
		span {
			font-size: $font-size-lg;
			vertical-align: middle;
		}
	}
}

.card-flex {
	@extend .card;
	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.wechat_qr_code {
	max-width: 100%;
	width: 150px;
	height: 150px;
	max-height: auto;
	@include media-breakpoint-down(md) {
		display: none;
	}
}