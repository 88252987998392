.block-author-wrapper {
  background: white;
  padding: $lg-gap 0;
  margin-top: 0;
  margin-bottom: 0;
  @include media-breakpoint-down(xs) {
    margin-bottom: 0;
    padding: $md-gap;
  }
}

.block-author {
  margin: $spacer auto;
  max-width: 35rem;
  @include media-breakpoint-down(md) {
    margin: 0 auto;
  }
  @include media-breakpoint-down(md) {
    padding: 0 $spacer;
  }
}
.author-name {
  font-size: $font-size-lg;
  font-weight: bold;
}

.author-description {
  font-size: $font-size-sm;
  font-family: $font-family-serif;
  color: $body-color;
  max-width: 35em;

}


.bio-photo {
  text-align: center;
  img {
    border-radius: 50%;
    // border: 3px solid $pale-blue;
    width: 150px;
    height: 150px;
    object-fit: cover;

  }
}
.bio-photo__xs {
  float: right;
  img {
    width: 100px;
    height: 100px;
    margin: 0 0 $spacer $spacer;
  }
}
.read-more-contact {
  margin-top: $md-gap;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}