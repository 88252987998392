#mc_embed_signup {
	input[type="email"] {
		display: inline-block;
		@extend .nav-type;
		outline: none;
	}
	input[type="submit"] {
		@extend .nav-type;
		display: inline-block;
	}
	.clear {
		display: inline-block;
	}
}