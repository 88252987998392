#sticky-wrapper {
  z-index: 999;
  transition: height 0.3s;
}

.tagline {
  font-style: italic;
  margin: 1em auto 3em;
  max-width: 20em;
  text-align: center;
  font-size: $font-size-base;
  font-family: $font-family-serif;
  line-height: 1.2;
}

.nav-wrapper {
  width: 100%;
  z-index: 999;
  background-color: $pale-beige;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .is-sticky & {
    box-shadow: 0 0 30px transparentize(black, 0.5);
    padding-left: 5vw;
    padding-right: 5vw;
    background-color: white;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  button.navbar-toggler {
    outline: none;
    width: 100%;
    text-align: left;
    height: 55px;
  }

}

.nav-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    padding-bottom: $md-gap;
  }
}

header.banner > .brand {
  display: block;
  text-align: center;
  .logo {
    max-width: 100%;
    width: 20em;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      max-width: 50%;
    }
  }
}

.nav-wrapper > .brand {
  opacity: 0;
  display: inline;
  left: 5vw;
  top: 10px;
  position: absolute;
  img {
    max-height: 55px;
    width: auto;
    @include media-breakpoint-down(sm) {
      max-height: 45px;
    }
  }
  .is-sticky & {
    opacity: 1;
    transition: opacity 1s;
    transition-delay: 0.5s;
  }
  @include media-breakpoint-down(sm) {
    right: 5vw;
    left: auto;
    top: 5px;
  }
}

.collapse.in {
  width: 100%;
  background-color: white;
}

nav {
  ul.nav {
    margin: 1rem 0;
    text-align: center;
    li {
      display: inline-block;
      @extend .nav-type;
      &:after {
        content: ' | ';
        margin: 0 .5em;
        color: $gray-light;
        font-weight: 300;
      }
      &:last-child:after {
        content: '';
      }
      &:first-child {
        margin-left: 1em;
      }
      a, button, a:not([href]) {
        cursor: pointer;
        color: $gray-light;
        background-color: transparent;
        border: none;
        outline: none;
        transition: all 0.3s;
        &:hover {
          text-decoration: none;
          color: $body-color;
          transition: all 0.3s;
        }
      }
    }
  }
}

nav.sns-links ul {
  li {
    &:after {
      content: '';
      margin: 0 .3em;
    }
  }
}

nav.nav-top {
  @include media-breakpoint-down(sm) {
    order: 4;
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: block;
        flex-basis: 100%;
        font-size: $font-size-base;
        // font-weight: 300;
        padding: $spacer / 2 0;
        a, button, a:not([href]) {
          color: $body-color;
        }
        &:after {
          content: '';
          margin: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
nav.sns-links {
  @include media-breakpoint-down(sm) {
    order: 1;
    ul {
      li {
        font-size: $font-size-lg;
        margin: 0;
        a, button, a:not([href]) {
          color: $body-color;
        }
      }
    }
  }
}

.hidden-wrapper {
  order: 2;
  display: none;
  width: 100%;
  margin-bottom: $md-gap;
  flex-basis: 100%;
  text-align: center;
  padding: $md-gap;
  background-color: $pale-blue;
  .is-sticky & {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0 0 $spacer;
  }
  input.search-field, input.email {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid darken($pale-blue, 20%);
    @extend .nav-type;
    margin-right: $spacer;
    color: $body-color;
  }
}

#mc_embed_signup .clear {display: inline-block;}
