html {
  font-size: 18px;
  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  background-color: $pale-beige;
}

.lang-zh-heading {
  font-family: $font-family-zh-sans-serif;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: none;
}
.lang-zh-content {
  font-family: $font-family-zh-serif;
  line-height: 1.8;
  text-transform: none;
  font-weight: 500;
}

ul, menu, dir {
  -webkit-padding-start: 0;
}

img {
  max-width: 100%;
}

button {
  outline: none;
}

abbr {
  font-family: $font-family-serif;
  font-style: normal;
  text-transform: lowercase;
  // font-weight: bold;
  letter-spacing: 1px;
  font-variant: small-caps;
}
.h1, h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

.h2, h2 {
  font-size: 1.5rem;
}

.h3, h3 {
  font-size: 1.25rem;
}

.h4, h4 {
  font-size: 1rem;
}

.excerpt {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: $font-family-sans-serif;
  color: $gray-light;
  font-size: $font-size-xs;
}

article.featured {
  .entry-content {
    @include media-breakpoint-down(sm) {
      margin-bottom: $md-gap;
    }
  }
  .excerpt {
    max-width: 35em;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    color: $body-color;
    @include media-breakpoint-down(sm) {
      padding: 0 $spacer;
      font-size: $font-size-sm;
    }
  }
  &.lang-zh {
    .excerpt {
      @extend .lang-zh-content;
    }
  }
  ul.tags {
    li {
      font-family: $font-family-serif;
      font-size: $font-size-base;
      background-color: transparent;
      color: $body-color;
      text-decoration: underline;
      &:first-child {
        padding-left: 0;
      }
      &:before {
        content: '#';
      }
    }
  }
}

.post-thumbnail {
  object-fit: cover;
  width: 100%;
  height: 200px;
  @include media-breakpoint-down(sm) {
    margin-bottom: $spacer;
    height: 150px;
  }
}

ul.tags {
  li {
    display: inline-block;
    list-style-type: none;
    font-size: 60%;
    background-color: $gray-lighter;
    font-family: $font-family-sans-serif;
    padding: 0.2em 0.5em;
    margin-left: 0; 
    margin-right: 1em;
    a {
      text-decoration: none;
      color: $gray-light;
      &:before {
        // font-family: 'patsg';
        content: '#';
        font-size: $font-size-xs;
        // padding-right: 0.5em;
        color: $gray-light;
      }
    }
  }
}

article {
  margin-bottom: $lg-gap;
  @include media-breakpoint-down(sm) {
    margin-bottom: $md-gap;
  }
}

.media-logo {
  margin: 1rem;
  max-width: 5em;
}

.entry-content h2 {
  font-size: 1.5rem;
  font-family: $font-family-sans-serif;
  // font-style: italic;
  margin-top: 2em;
  margin-bottom: 0.5em;
  // text-align: center;
}

.entry-content blockquote {
  font-style: italic;
  font-size: 130%;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: -1em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.3;
  border-left: 5px solid $gray-lighter;
  .lang-zh & {
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.8em;
  }
  p:last-child {
    margin-bottom: 0;
  }
  cite {
    padding-top: $spacer * 1;
    font-size: 0.75rem;
    font-weight: normal;
    font-family: $font-family-zh-serif;
    font-style: normal;
    display: block;
    span.name {
      font-style: normal;
      text-transform: uppercase;
      display: block;
      font-family: $font-family-sans-serif;
      font-weight: bold;
      letter-spacing: -1px;
      font-size: 0.7rem;
    }
  }
}

.entry-content figure {
  margin: $spacer auto;
}

.entry-content figcaption {
  margin-top: $spacer / 2;
  margin-bottom: $spacer * 1.5;
  font-size: 16px;
  line-height: 1.2;
  color: $body-color;
  // font-style: italic;
  // text-align: center;
  cite {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    display: block;
    font-size: 16px;
    font-family: $font-family-sans-serif;
  }
  .lang-zh & {
    font-family: $font-family-zh-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.2;
    font-style: normal;
  }
}

body.single .entry-meta {
  font-size: 85%;
  font-family: $font-family-sans-serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.1em;
  color: $light-gray;
  a {
    color: $light-gray;
  }
}

.question {
  font-size: $font-size-sm;
  font-family: $font-family-sans-serif;
  font-weight: bold;
}

.answer  {
  padding-left: 1em;
  border-left: 3px solid $soft-pink;
  margin: 1em 0 2em;
}

.page-header {
  margin-bottom: $md-gap;
  text-align: center;
}

.section-title, .section-content {
  width: 100%;
}

.section-title {
  padding: $spacer 0;
  text-align: center;
  font-family: $font-family-sans-serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  &__copyright {
    text-transform: none;
    font-variant: none;
    font-size: $font-size-xs;
    letter-spacing: 0;
    color: white;
    background-color: $black;
  }
}

.section-content {
  padding: $spacer * 3 0;
  
}

.full-width {
  margin: 0 auto;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
}

.nav-type {
  // color: $body-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-xs;
  font-weight: 500;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}

.card-text.entry-content {
  font-size: $font-size-sm;
}

.side-bar .card-text {
  font-size: $font-size-xs;
}

.card-title {
  @extend .nav-type;
}

.card-deck {
  margin: 0 auto;
}

body.home .other-posts {
  padding-top: $lg-gap;
}