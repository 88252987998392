.gallery {
	@extend .row;
	figure img {
		max-width: 100%;
	}
}
.gallery-columns-2 {
	.gallery-item {
		@extend .col-md-6;
	}
}
.gallery-columns-3 {
	.gallery-item {
		@extend .col-md-4;
	}
}

.slideshow-window {
	background-color: transparent;
	border-color: transparent;
}