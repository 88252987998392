.comment-list {
	@extend .list-unstyled;
}
.comment-list ol {
	list-style: none;
}
.comment-form p {
	@extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
	@extend .form-control;
}
.comment-form input[type="submit"] {
	@extend .btn;
	@extend .btn-secondary;
}

.comments {
	.comment-section-title, .comment-reply-title {
		@extend .nav-type;
		text-align: center;
		padding: $md-gap 0;
		margin: 0;
	}
}

.comments-wrapper {
	max-width: 35em;
	margin-left: auto;
	margin-right: auto;
}

.comment {
	background-color: white;
	margin-bottom: $md-gap;
	.comment-body {
		border-top: 3px double $pale-blue;

	}
}

.depth-1>.comment-body  {
	border-top: none;
}
.comment-body {
	padding: $spacer *2 $spacer * 2 $spacer;
	.comment-content {
		font-size: $font-size-sm;
		font-family: $font-family-serif;
	}
	.comment-author {
		font-size: $font-size-base;
		cite {
			font-style: normal;
			padding: 0 0.3em;
			@extend .nav-type;
		}
		.avatar {
			border-radius: 50%;
		}
	}
	blockquote {
		padding: 1em;
		font-style: italic;
	}
}

.comment-time {
	@extend .nav-type;
	font-style: normal;
	font-size: $font-size-xs;
	color: $gray-light;
	text-align: right;
	a {
		color: $gray-light;
	}
}
// .reply {
// 	text-align: right;
.reply-btn {
	float: right;
	border-bottom: 1px solid $body-color;
	@extend .nav-type;
	a:hover {
		text-decoration: none;
	}
}
// }



form.comment-form {
	font-size: $font-size-xs;
	.logged-in-as, label {
		font-family: $font-family-serif;
		font-style: italic;
		text-align: center;
	}
}