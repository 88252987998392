footer.contact-info {
	margin-top: $lg-gap;
	padding-top: 2.75rem;
	padding-bottom: $md-gap;
	background-color: invert($pale-beige);
	color: $pale-beige;
	.footer-sns {
		@extend .full-width;
		text-align: center;
	}
	#mc_embed_signup {
		input[type="email"] {
			border: 1px solid white;
			text-align: center;
			font-size: .75rem;
			@extend .nav-type;
			outline: none;
			margin-bottom: $spacer / 2; 
		}
		input[type="submit"] {
			border: none;
			border-bottom: 1px solid white;
			background-color: transparent;
			color: white;
		}
	}
}

ul.sns-icons {
	margin-bottom: 0;
	li {
		display: inline-block;
		font-size: $font-size-lg;
		padding: 0.5em;
		a, button {
			color: $pale-beige;
			background-color: transparent;
			border: none;
		}
	}
}

.copyright {
	text-align: center;
	margin-top: $md-gap;
}

.wechat-popup-wrapper {
	position: fixed;
	display: none;
	top: 25%;
	left: 25%;
	right: 25%;
	background-color: transparentize($yellow, 0);
	color: $body-color;
	.wechat-popup {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		text-align: center;
		min-height: 600px;
		img {
			margin-bottom: $spacer;
			max-width: 200px;
			height: auto;
			@include media-breakpoint-down(xs) {
				max-width: 50%;
				height: auto;
			}
		}
		.wechat-id {
			@extend .nav-type;
			font-size: $font-size-lg;
		}
		button {
			opacity: 1;
			background-color: transparent;
			border: none;
			outline: none;
			font-size: $font-size-xs;
			vertical-align: middle;
			@extend .nav-type;
			position: absolute;
			right: $spacer / 2;
			top: $spacer / 2;
			@include media-breakpoint-down(xs) {
				position: relative;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}	
}