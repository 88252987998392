// Grid settings
$enable-flex:           true;
$enable-rounded:        false;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$pale-blue: #E1E8F5;
$yellow: #FFEA7A;
$soft-pink: #F5B4B1;
$pale-orange: #FBD9C5;
$orange: #F4C9AE;
$light-gray: #CCCCCC;
$pale-beige: #fcf9f6;

$brand-primary:         $pale-blue;
$black:                 #000000;
$body-color:            $black;
$link-color:            $body-color;

// Typefaces
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:
// Georgia,
// "Minion Pro",
"Times New Roman", Times, serif !default;
$font-family-heading: Rubik;
$font-family-zh-sans-serif: 'times-italic','PingFang SC',"source-han-sans-simplified-c",'Hiragino Sans GB','Microsoft Yahei'; 
$font-family-zh-serif: 	'times-italic','Songti SC',"source-han-serif-sc",serif; 
$font-family-base: $font-family-sans-serif;
// $headings-font-family: $font-family-heading;
$headings-font-weight:   700;

$card-border-width: 0;
$card-bg: transparent;

$spacer: 1rem;
$lg-gap: $spacer * 4;
$md-gap: $spacer * 2;