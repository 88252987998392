/*rubik-300 - latin */
// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 300;
//   src: url('../fonts/rubik-v2-latin-300.eot'); /* IE9 Compat Modes */
//   src: local('Rubik Light'), local('Rubik-Light'),
//   url('../fonts/rubik-v2-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('../fonts/rubik-v2-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//   url('../fonts/rubik-v2-latin-300.woff') format('woff'), /* Modern Browsers */
//   url('../fonts/rubik-v2-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//   url('../fonts/rubik-v2-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
// }
/* rubik-regular - latin */
// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/rubik-v2-latin-regular.eot'); /* IE9 Compat Modes */
//   src: local('Rubik'), local('Rubik-Regular'),
//   url('../fonts/rubik-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('../fonts/rubik-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//   url('../fonts/rubik-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
//   url('../fonts/rubik-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//   url('../fonts/rubik-v2-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
// }
/* rubik-500 - latin */
// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../fonts/rubik-v2-latin-500.eot'); /* IE9 Compat Modes */
//   src: local('Rubik Medium'), local('Rubik-Medium'),
//   url('../fonts/rubik-v2-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('../fonts/rubik-v2-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
//   url('../fonts/rubik-v2-latin-500.woff') format('woff'), /* Modern Browsers */
//   url('../fonts/rubik-v2-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
//   url('../fonts/rubik-v2-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
// }

@font-face {
  font-family: 'patsg';
  src: url('../fonts/patsg.eot?43058264');
  src: url('../fonts/patsg.eot?43058264#iefix') format('embedded-opentype'),
  url('../fonts/patsg.woff2?43058264') format('woff2'),
  url('../fonts/patsg.woff?43058264') format('woff'),
  url('../fonts/patsg.ttf?43058264') format('truetype'),
  url('../fonts/patsg.svg?43058264#patsg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'patsg';
    src: url('../fonts/patsg.svg?43058264#patsg') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "patsg";
  font-style: normal;
  font-weight: normal;
  speak: none;
  
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-tag:before { content: '\e802'; } /* '' */
.icon-pencil:before { content: '\e803'; } /* '' */
.icon-cancel:before { content: '\e804'; } /* '' */
.icon-cancel-circle:before { content: '\e805'; } /* '' */
.icon-menu-1:before { content: '\e806'; } /* '' */
.icon-cancel-2:before { content: '\e808'; } /* '' */
.icon-cancel-circled:before { content: '\e809'; } /* '' */
.icon-menu-2:before { content: '\f008'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-rss-squared:before { content: '\f143'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-weibo:before { content: '\f18a'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-hashtag:before { content: '\f292'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */