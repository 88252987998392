.single-post {
  article {
    margin-top: 0;
  }
  article.post header {
    text-align: center;
    .entry-title {
      margin: 0 auto $spacer;
    }
  }

  .entry-content, .entry-summary {
    max-width: 35em;
    margin-left: auto;
    margin-right: auto;
    font-size: 1em;
    img {
      max-width: 100%;
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 $spacer;
    }
  }


  article:last-child {
    margin-bottom: 0;
  }
}

.entry-content {
  margin-bottom: $lg-gap;
  font-family: $font-family-serif;
  .lang-zh & {
    @extend .lang-zh-content;
  }
  p {
    margin-bottom: 1em; 
    .lang-zh & {
      margin-bottom: 1.8em;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: "";
      border-top: 3px solid $soft-pink;
      padding-top: 10px;
      display: block;
      width: 2.5em;
      margin-left: -1em;
      padding-bottom: .5em;
    }
    .lang-zh & {
      // @extend .lang-zh-heading;
    }
  }
  hr {
    border-color: $pale-blue;
    border-width: 3px;
    width: 2.5em;
    margin: 2.5em auto;
  }
}