.header-bg {
  text-align: center;
  background-size: cover;
  background-position: center;
  height: 90vh;
  max-height: 972px;
  width: 90vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -45vw;
  margin-right: -45vw;
  margin-bottom: $lg-gap;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    width: 100vw;
    height: auto;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: $md-gap;
  }

  header {
    max-width: 35em;
    z-index: 10;
    position: relative;
    // border: 3px double rgba(255,255,255,0);
    padding: 3em;
    transition: all 0.5s;
    transition-delay: 0.15s;

  }

  h1 {
    color: white;
    font-family: Didot;
    font-weight: 400;
    text-transform: uppercase;
    .lang-zh & {
      @extend .lang-zh-heading;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }

  h2.subtitle {
    color: white;
    font-weight: 400;
    font-size: 1.25rem;
    margin-top: $md-gap;
    font-family: $font-family-serif;
    font-style: italic;
    .lang-zh & {
      @extend .lang-zh-content;
      font-size: 0.8rem;
      letter-spacing: 2px;
      line-height: 1.5;
      font-style: normal;
      margin-left: auto;
      margin-right: auto;
      width: 20rem;
    }
  }

  .categories {
    margin-bottom: $md-gap;
    font-family: $font-family-sans-serif;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 0.3em;
    color: white;
    a {
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .excerpt {
    color: white;
  }
}

.veil {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s;
  transition-delay: 0.15s;
}

.header-bg .veil__header-bg {
  z-index: 0;
  background-color: transparentize(black, 1);
}

.header-bg:hover {
  .veil__header-bg {
    background-color: transparentize(black, 0.5);
    z-index: 0;
    body.single & {
      background-color: transparent;
    }
  }
  header {
    transition: all 0.5s;
    // border: 3px double rgba(255,255,255,0);
    transition-delay: 0.15s;
    transform: scale(1.1);
    body.single & {
      transition: none;
      transform: none;
    }
  }

  header .veil__header {
    background-color: transparentize($soft-pink, 0.25);
    body.single & {
      background-color: transparentize(black, 0.2);
    }
  }
}

.header-bg header .veil__header {
  z-index: -1;
  background-color: transparentize(black, 0.5);
  @include media-breakpoint-down(sm) {
    background-color: transparentize(black, 0.5);
  }
  body.single & {
    background-color: transparentize(black, 0.2);
    @include media-breakpoint-down(sm) {
      background-color: transparentize(black, 0.5);
    }
  }
}