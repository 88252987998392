.btn {
	@extend .nav-type;
}
.continue-reading {
	margin-top: $spacer;
	@extend .nav-type;
}

.btn-primary {
	background-color: darken($brand-primary, 10%);
	border-color: darken($brand-primary, 12%);
}